import React, { Component } from "react"
import $ from 'jquery';

var isLoad = false;

function AnimateBanner() {
    try {
        var listSize = $(".banner-image")[0].children.length - 1;
        for (var i = 0; i < listSize; i++) {
            var children = $(".banner-image")[0].children;
            if (children[i].className == "img-nofade" && i + 1 < listSize) {
                children[i].classList.remove("img-nofade");
                children[i].classList.add("img-fade");
                children[i + 1].classList.remove("img-fade");
                children[i + 1].classList.add("img-nofade");
                break;
            }
            else if (children[i].className == "img-nofade" && i + 1 == listSize) {
                children[i].classList.remove("img-nofade");
                children[i].classList.add("img-fade");
                children[0].classList.remove("img-fade");
                children[0].classList.add("img-nofade");
                break;
            }
        }
        setTimeout(AnimateBanner, 5000);
    }
    catch {

    }
 }

const BannerImage = () => {
    if(!isLoad){
        setTimeout(AnimateBanner, 5000);
        isLoad = true;
    }
    
    return <div className={"banner-image"}>
        <img src="banner-2.jpg" className={"img-nofade"} width={"100%"} style={{margin:"10px"}, {height:"70vh"}}/>
        <img src="digital-certificate-page.png" className={"img-fade"} width={"100%"} style={{margin:"10px"}, {height:"70vh"}}/>
        <img src="usb-token-page.jpg" className={"img-fade"} width={"100%"} style={{margin:"10px"}, {height:"70vh"}}/>
        <div>
            <h1 className={"text-1"}>Welcome!</h1>
            <h1 className={"text-2"}>to</h1>
            <h1 className={"text-3"}>Koteshwar Marketing</h1>
        </div>
    </div> 
    
}

export default BannerImage;