import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';

import './custom.css'
import USBToken from './Pages/USBToken';
import DigitalCertificate from './Pages/DigitalCertificate';
import ContactUs from './Pages/ContactUs';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/usbtoken' component={USBToken} />
            <Route path='/digitalcertificate' component={DigitalCertificate} />
            <Route path='/contactus' component={ContactUs} />
      </Layout>
    );
  }
}
