import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react"

library.add(fas,fab);

// class Footer extends Component {
//     render(){
//         return <div class="footer row">
//         <div className={"col-md-12 col-sm-12"}><div className="btop"></div></div>
//         <div className="col-md-8 col-sm-8">
//             <div className="col-sm-5" style={{display: "inline-block"}} />
//             <ul className="list-inline copyright">
//                 <li className="silver">© Koteshwar Marketing. All Rights Reserved.</li>
//             </ul>
//         </div>
//         <div className="col-md-4 col-sm-4">
//             <div className="col-sm-5" style={{display: "inline-block"}} />
//             <ul className="dark-social space-bottom-0 list-inline">
//                 <li className="facebook list-inline-item"><a href="https://www.facebook.com/eMudhra" target="_blank"><FontAwesomeIcon icon={["fab", "facebook"]} color={"black"}/></a></li>
//                 <li className="twitter list-inline-item"><a href=" https://twitter.com/eMudhra" target="_blank"><FontAwesomeIcon icon={["fab", "twitter"]} color={"black"}/></a></li>
//             </ul>
//         </div>
//     </div>
//     }
// }


class Footer extends Component{
    render(){
        return <div className={"footer"}>
            <h3>Koteshwar Marketing</h3>
           <div className={"row"}>
                <div className={"col-sm-6"}>
                    <p>© Koteshwar Marketing. All Rights Reserved.</p>
                </div>
                <div className={"col-sm-6"}>
                    <FontAwesomeIcon icon={["fab", "facebook"]} color={"white"} style={{margin:"10px"}}/>
                    <FontAwesomeIcon icon={["fab", "twitter"]} color={"white"} style={{margin:"10px"}}/>
                </div>
           </div>
        </div>
    }
}

export default Footer;