import React, { Component, useState, useRef } from "react"
import ReactDom from "react-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Modal.css";
import BannerImage from './Banner';
import $ from 'jquery';

library.add(fas, fab);

export class Home extends Component {
    render() {
        return <div>
            <Page />
        </div>
    }
}

const Page = () => {
    return <div>
        <BannerOver />
        {/* <Products /> */}
        <ProductCategories />
        <AboutUs />
        <SetupModal />
        {/* <Associates />
        <About /> */}
    </div>
}

const BannerOver = () => {
    return <div className={"banner-over"}>
        <BannerImage />
    </div>
}

const LoadCategories = () => {
    const categories = [];
    //$.ajax({
    //    type: "POST"
    //    , async: false
    //    , headers: {
    //        "Access-Control-Allow-Origin": "*"
    //    }
    //    , url: baseUrl + "GetCategories"
    //    , contentType: "application/json"
    //    , success: function (data) {
    //        for (let category of data) {
    //            if (category.parentCategory != null) {
    //                categories.push(<LoadProducts params={[category.id, category.name, category.parentCategory]} />)
    //            }
    //        }
    //    }
    //});
    return <div>
        {categories}
    </div>
}

const LoadProducts = ({ params }) => {
    const products = [];
    //$.ajax({
    //    type: "POST"
    //    , async: false
    //    , headers: {
    //        "Access-Control-Allow-Origin": "*"
    //    }
    //    , url: baseUrl + "GetProductsByCategoryID"
    //    , contentType: "application/json"
    //    , data: JSON.stringify(params[0])
    //    , success: function (data) {
    //        productData = data;
    //        for (let product of productData) {
    //            products.push(<ProductImageCard product={[product.name, product.productImagePath]} />)
    //        }
    //    }
    //});
    return <div className={"products-container"}>
        <h3>{params[2]}</h3>
        <h5>{params[1]}</h5>
        <div className={"inner-container"}>
            {products}
        </div>
    </div>
}

const Products = () => {
    return <div className={"products"}>
        <h2>Products We Offer</h2>
        <LoadCategories />
    </div>
}

const ProductImageCard = ({ product }) => {
    var name = { product }.product[0];
    var image = { product }.product[1];
    return <div className={"product-card"}>
        <div className={"image-container"}>
            <img src={image} />
        </div>

        <h5>{name}</h5>
        <input type={"button"} className={"product-button"} value={"Get Quotes"} />
    </div>
}

const ProductCategories = () => {
    return <div className={"category"}>
        <div className={"row heading"}>
            <div className={"col-sm-4 mx-auto heading-body"}>
                <h2>We Offer</h2>
            </div>
        </div>
        <div className={"row container"}>
            <div className={"col-xl-4"}>
                <ProductCategoryCard params={["USB Token", "usb-token.png", "Check Out", "usbtoken"]} />
            </div>
            <div className={"col-xl-4"}>
                <ProductCategoryCard params={["Digital Signing Certificate", "dsc.png", "Check Out", "digitalcertificate"]} />
            </div>
            <div className={"col-xl-4"}>
               
                <ProductCategoryCard params={["Become Our Associate", "join-us.png", "Join Us", "joinus"]} />
            </div>
        </div>
    </div>
}

function NavigateToPage(path) {
    var baseUrl = window.location.href;
    if (path == "joinus") {
        $("#joinusModal").click();
    }
    else {
        window.location = baseUrl + path;
    }
}

const ProductCategoryCard = ({ params }) => {
    var name = { params }.params[0];
    var image = { params }.params[1];
    var buttonName = { params }.params[2];
    var path = { params }.params[3];
    return <div className={"category-card"}>
        <div className={"image-container"}>
            <img src={image} />
        </div>
        <h3><a>{name}</a></h3>
        <button className={"default-button-input"} type={"button"} onClick={() => NavigateToPage(path)}>{buttonName}</button>
    </div>
}

const AboutUs = () => {
    return <div className={"category"}>
        <div className={"row heading"}>
            <div className={"col-sm-4 mx-auto heading-body"}>
                <h2>About Us</h2>
            </div>
        </div>
        <div className={"row container"}>
            <div className={"col-xl-6"}>
                <img src={"about-us.jpg"} width={"50%"} />
            </div>
            <div className={"col-xl-6"}>
                <h5 style={{ marginTop: "40px" }}>Distributors & Licensed Registration Authority of Digital Signature Certificates having more than 400 channel partners/resellers across India and increasing.

Sole Distributors of Trustkey USB Token in India. Deals in Aladdin Token & ePass tokens.</h5>
            </div>
        </div>
    </div>
}

function SetupModal() {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };
    return (
        <div>
            <button onClick={openModal} className={"d-none"} id={"joinusModal"}></button>
            {showModal ? <PopupModal setShowModal={setShowModal} /> : null}
        </div>
    );


}

const PopupModal = ({ setShowModal }) => {
    // close the modal when clicking outside the modal.
    const modalRef = useRef();
    const closeModal = (e) => {
        if (e.target === modalRef.current) {
            setShowModal(false);
        }
    };
    //render the modal JSX in the portal div.
    return ReactDom.createPortal(
        <div className="popup-container" ref={modalRef} onClick={closeModal}>
            <div className="modal">
                <h2>Contact Request Form</h2>
                <div className={"input-section"}>
                    <div className={"empty"}>
                        <input id={"inputName"} type={"text"} onChange={OnTextChange} />
                        <span onClick={SetFocus}>Name</span>
                    </div>
                    <div className={"empty"}>
                        <input id={"inputMobileNumber"} type={"text"} onChange={OnTextChange} />
                        <span onClick={SetFocus}>Mobile Number</span>
                    </div>
                    <div className={"empty"}>
                        <input id={"inputEmail"} type={"text"} onChange={OnTextChange} />
                        <span onClick={SetFocus}>Email</span>
                    </div>
                    <div className={"empty"}>
                        <textarea id={"inputMessage"} onChange={OnTextChange} />
                        <span onClick={SetFocus}>Message</span>
                    </div>
                    <div>
                        <input type={"submit"} className={"submit-button"} value={"Submit"} />
                    </div>
                </div>
                <button onClick={() => setShowModal(false)}><FontAwesomeIcon icon={["fas", "times"]} color={"red"} /></button>
            </div>
        </div>,
        document.getElementById("popupModal")
    );
}

function SetFocus(object) {
    if (object != undefined) {
        var id = object.target.parentElement.children[0].id;
        $("#" + id).focus();
    }
}

function OnTextChange(object) {
    if (object != undefined) {
        var id = object.target.id;
        if (id != "" && $("#" + id).val() != "") {
            $("#" + id).parent().removeClass("empty");
            $("#" + id).parent().addClass("no-empty");
        }
        else if (id != "" && $("#" + id).val() == "") {
            $("#" + id).parent().removeClass("no-empty");
            $("#" + id).parent().addClass("empty");
        }
    }
}


