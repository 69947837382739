import React, { Component, useState, useRef } from "react"
import ReactDom from "react-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Modal.css";
import $ from 'jquery';

library.add(fas,fab);


class ContactUs extends Component{
    render(){
        return <div className={"contact-page"}>
                <div id="popupModal"></div>
                <img src={"contact-us.jpg"} />
                <div className={"page-over"}>

                </div>
                <div className={"page-over-heading"}>
                    <h1>Contact Us</h1>
                </div>
                <div className={"row content"}>
                    <div className={"col-xl-6 info-box"}>
                        <h3>Koteshwar Marketing</h3>
                        <div class={"info"}>
                            <FontAwesomeIcon icon={["fas", "map-marker"]} color={"black"}/>
                            <div>Ph-1, 201-202 , C-15, Acharya Niketan, Mayur Vihar, New Delhi, Delhi 110091</div>
                        </div>
                        <div class={"info"}>
                            <FontAwesomeIcon icon={["fas", "phone"]} color={"black"}/>
                            <div>+91 8285111200</div>
                        </div>
                        {/* <div class={"info"}>
                            <FontAwesomeIcon icon={["fas", "envelope"]} color={"black"}/>
                            <div>contact@kmar.com</div>
                        </div> */}
                    <a className={"whatsapp-button"} href={"https://wa.me/918285111200"} rel={"noreferrer noopener"} target={"_blank"}>
                            <FontAwesomeIcon icon={["fab", "whatsapp"]} color={"white"}/>
                            <span>Message on whatsapp</span>
                        </a>
                         <SetupModal /> 
                    </div>
                    <div className={"col-xl-6"}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7005.442440215091!2d77.294747!3d28.608139!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5430ea8b4086a5d1!2sKoteshwar%20Marketing%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1625318126114!5m2!1sen!2sin" width="100%" height="600" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
        </div>
    }
}

function SetupModal(){
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
      };
      return (
          <div>
              {/*<button onClick={openModal} className={"contact-button"}>Open Contact Request Form</button>*/}
          {showModal ? <PopupModal setShowModal={setShowModal} /> : null}
        </div>
      );

    
}

const PopupModal = ({ setShowModal }) =>{
    // close the modal when clicking outside the modal.
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };
  //render the modal JSX in the portal div.
  return ReactDom.createPortal(
    <div className="popup-container" ref={modalRef} onClick={closeModal}>
      <div className="modal">
        <h2>Contact Request Form</h2>
        <div className={"input-section"}>
            <div className={"empty"}>
              <input id={"inputName"} type={"text"} onChange={OnTextChange}/>
              <span onClick={SetFocus}>Name</span>
            </div>
            <div className={"empty"}>
              <input id={"inputMobileNumber"} type={"text"} onChange={OnTextChange}/>
              <span onClick={SetFocus}>Mobile Number</span>
            </div>
            <div className={"empty"}>
              <input id={"inputEmail"} type={"text"} onChange={OnTextChange}/>
              <span onClick={SetFocus}>Email</span>
            </div>
            <div className={"empty"}>
              <textarea id={"inputMessage"} onChange={OnTextChange}/>
              <span onClick={SetFocus}>Message</span>
            </div>
            <div>
              <input type={"submit"} className={"submit-button"} value={"Submit"}/>
            </div>
        </div>
        <button onClick={() => setShowModal(false)}><FontAwesomeIcon icon={["fas", "times"]} color={"red"}/></button>
      </div>
    </div>,
    document.getElementById("popupModal")
  );
}

function SetFocus(object){
  if(object != undefined){
    var id = object.target.parentElement.children[0].id;
    $("#" + id).focus();
  }
}

function OnTextChange(object){
  if(object != undefined){
    var id = object.target.id;
    if(id != "" && $("#" + id).val() != ""){
      $("#" + id).parent().removeClass("empty");
      $("#" + id).parent().addClass("no-empty");
    }
    else if(id != "" && $("#" + id).val() == ""){
      $("#" + id).parent().removeClass("no-empty");
      $("#" + id).parent().addClass("empty");
    }
  }
}


export default ContactUs;