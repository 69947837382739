import React, { Component, useState, useRef } from "react"
import ReactDom from "react-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Modal.css";
import $ from 'jquery';

library.add(fas,fab);

const headerImage = "";
const categoryId = 0;
const heading = "";
const itemPerRow = 2;
var itemForQuotes;

var baseUrl = location.origin;

class Product extends Component{
    constructor({params}){
        super(params);
        this.headerImage = params[0];
        this.categoryId = params[1];
        this.heading = params[2];
    }

    render(){
        return <div className={"product-page"}>
            <div id="popupModal"></div>
            <img className={"header-image"} src={this.headerImage} />
            <div className={"page-over"}>
                
            </div>
            <div className={"page-over-heading"}>
                <h1>{this.heading}</h1>
            </div>
            <div className={"content"}>
                <LoadProducts params={[this.categoryId]}/>
            </div>
        </div>
    }
}

const LoadProducts = ({params}) =>{
    const products = [];
    $.ajax({
        type:"POST"
        ,async:false
        , url: baseUrl + "/Database/GetProductsByCategoryID"
        ,contentType: "application/json"
        ,data: JSON.stringify(params[0])
        ,success:function (data){
            var productsRow = [];
            for(var i = 0; i < data.length; i++){
                productsRow.push(data[i]);
                if(i + 1 % itemPerRow === 0 || i + 1 === data.length){
                    if(i > 0){
                        products.push(<ProductRow products={productsRow}/>)
                    }
                    productsRow = [];
                }
            }
        }
    });
    return <div>
        {products}
    </div>;
}

const ProductRow = ({products}) =>{
   const productsPerRow = [];
    for(var i = 0; i < products.length; i++){
        productsPerRow.push(<ProductBlock product={products[i]}/>)
    }
    return <div className={"row my-2"}>
        {productsPerRow}
    </div>
}

const ProductBlock = ({product}) =>{
    if(product != null){
        return <div className={"col-md-" + 12 / itemPerRow}>
            <div className={"product-card"}>
                <div className={"image-container"}>
                    <img src={product.productImagePath} />
                </div>
                <div className={"description-container"}>
                    <OrganizeDescription params={[product.description]} />
                </div>
                <div className={"heading-container"}>
                    <h5>{product.name}</h5>
                </div>
            </div>
            {/* <SetupModal params={[product]}/> */}
        </div>
    }
    return "";
}

const OrganizeDescription = ({params}) =>{
    const descArray = params[0].split(";");
    const outArray = [];
    for(var i = 0 ; i < descArray.length; i++){
        outArray.push(<span>{descArray[i]}</span>);
        outArray.push(<br />);
    }
    return <div>
        {outArray}
    </div>
}

function SetupModal(params){
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
        itemForQuotes = params.params[0];
      };
      return (
        <div>
          <button onClick={openModal} className={"default-button-input"}>Get Quotes</button>
          {showModal ? <PopupModal setShowModal={setShowModal} /> : null}
        </div>
      );

    
}

const PopupModal = ({ setShowModal }) =>{
    // close the modal when clicking outside the modal.
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };
  //render the modal JSX in the portal div.
  return ReactDom.createPortal(
    <div className="popup-container" ref={modalRef} onClick={closeModal}>
      <div className="modal product-modal">
        <div className={"row product-body"}>
            <div className={"col-sm-6"}>
                <div className={"image-block"}>
                    <img src={itemForQuotes.productImagePath} />
                </div>
            </div>
            <div className={"col-sm-6 content-block"}>
                <h5>{itemForQuotes.name}</h5>
                <div className={"description-container"}>
                    <OrganizeDescription params={[itemForQuotes.description]} />
                </div>
                <div className={"price-block"}>
                    <div className={"quantity-block"}>
                        <button onClick={() => UpdateQuantity(1)}>-</button>
                        <div><input type={"text"} id={"productQuantity"} placeholder={0}></input></div>
                        <button onClick={() => UpdateQuantity(-1)}>+</button>
                    </div>
                    <button className={"default-button-input"} onClick={GetPrice}>Get Price</button>
                    <div className={"result-block"}>
                        <span>Price: ₹ </span>
                        <span id={"productPrice"}></span>
                        <div>
                            <p>Note: Price shown here is excluisive of GST</p>
                        </div>
                    </div>
                   
                </div>
                
            </div>
        </div>
        <button onClick={() => setShowModal(false)}><FontAwesomeIcon icon={["fas", "times"]} color={"red"}/></button>
      </div>
    </div>,
    document.getElementById("popupModal")
  );
}

function UpdateQuantity(qty){
    var currentQty = $("#productQuantity").val();
    if(qty > 0 && currentQty > 0 || qty < 0){
        var newQty = currentQty - qty;
        $("#productQuantity").val(newQty)
    }
    
}

function GetPrice(){
    var pricemodel = {
        "ProductID": itemForQuotes.id,
        "Quantity" : parseInt($("#productQuantity").val())
    }
    $.ajax({
        type:"POST"
        ,async:false
        ,headers:{
            "Access-Control-Allow-Origin":"*"
        }
        ,url: baseUrl + "GetPrice"
        ,contentType: "application/json"
        ,data: JSON.stringify(pricemodel)
        ,success:function (data){
            $("#productPrice").html(data);
        }
    });
}

export default Product;